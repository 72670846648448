<template>
  <div class="help_wrap">
    <iframe src="https://sunraydtk.oss-cn-beijing.aliyuncs.com/ddup_help/ddup_gz_help.pdf#toolbar=0"
            width="100%"
            height="100%"></iframe>

  </div>

</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.help_wrap {
  height: calc(100vh - 60rem);
  margin-top: -80rem;
}
</style>